import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

export const createErrorReporterEnhancer = Sentry.createReduxEnhancer;

/**
 * Wrapper for our error reporting library
 */

const ErrorReporter = {
  enabled: true,
  logger: Sentry ? Sentry : false, // new Rollbar(),
  ErrorBoundary: Sentry.ErrorBoundary,

  configure({ integrations = [], ...config }) {
    if (ErrorReporter.logger === Sentry) {
      ErrorReporter.logger.init({
        enabled: ErrorReporter.enabled,
        normalizeDepth: 10,
        ignoreErrors: [
          // Random plugins/extensions
          'top.GLOBALS',
          // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
          'originalCreateNotification',
          'canvas.contentDocument',
          'MyApp_RemoveAllHighlights',
          'http://tt.epicplay.com',
          "Can't find variable: ZiteReader",
          'jigsaw is not defined',
          'ComboSearch is not defined',
          'http://loading.retry.widdit.com/',
          'atomicFindClose',
          // Facebook borked
          'fb_xd_fragment',
          // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
          // reduce this. (thanks @acdha)
          // See http://stackoverflow.com/questions/4113268
          'bmi_SafeAddOnload',
          'EBCallBackMessageReceived',
          // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
          'conduitPage',
          'Failed to fetch dynamically imported module',
        ],
        denyUrls: [
          // Facebook flakiness
          /graph\.facebook\.com/i,
          // Facebook blocked
          /connect\.facebook\.net\/en_US\/all\.js/i,
          // Woopra flakiness
          /eatdifferent\.com\.woopra-ns\.com/i,
          /static\.woopra\.com\/js\/woopra\.js/i,
          // Chrome extensions
          /extensions\//i,
          /^chrome:\/\//i,
          // Other plugins
          /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
          /webappstoolbarba\.texthelp\.com\//i,
          /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
        ],

        ...config,

        integrations: [...integrations, new Integrations.BrowserTracing()],
      });
    }

    return this;
  },

  setSessionUrlCallback(cb) {
    if (ErrorReporter.logger === Sentry) {
      Sentry.configureScope((scope) => {
        if (cb(scope)) {
          scope.setExtra('sessionURL', cb(scope));
        }

        return scope;
      });
    }

    return this;
  },

  setUserContext(data) {
    if (ErrorReporter.logger === Sentry) {
      Sentry.setUser(data);
    }

    return this;
  },

  identify(uuid, data) {
    this.setUserContext(data);
  },

  report(error, options = {}) {
    let level = 'error';

    if (typeof options === 'string') {
      level = options;
    }

    if (typeof options.level !== 'undefined') {
      level = options.level;
    }

    if (ErrorReporter.logger === Sentry) {
      const { level: _, ...extra } = options;
      Sentry.withScope((scope) => {
        scope.setExtras(extra);
        Sentry.captureException(error, level);
      });
    } else {
      console.info('Unhandled error message, throwing');
      throw error;
    }
  },
};

export const reportForkErrors = function (forks) {
  forks.forEach((f) => {
    f.toPromise().catch((e) => {
      ErrorReporter.report(e);
    });
  });
};

export default ErrorReporter;
